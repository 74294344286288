import React, { useState } from 'react';
import './style.css'; // Ensure this points to your CSS file

// Importing product images
import Product1 from "../../assets/product-1.png";
import Product2 from "../../assets/product-2.png";
import Product3 from "../../assets/product-3.png";
import Product4 from "../../assets/product-4.png";
import Product5 from "../../assets/product-5.png";
import Product6 from "../../assets/product-6.png";
import Product7 from "../../assets/product-7.png";
import Product8 from "../../assets/product-8.png";
import Product9 from "../../assets/product-9.png";
import Product10 from "../../assets/product-10.png";
import Product11 from "../../assets/product-11.png";



const ProductPage = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);

    // Product data
    const productData = [
        {
            id: 1,
            name: '30A BLDC ESC',
            image: Product1,
            price: '450 RS',
            description: 'Electronic Speed Controller for Brushless Motors',
            specifications: ['Item Type: Accessory', 'Material: ABS Plastic', 'Weight: 230g'],
            descriptionText: 'The 30A BLDC ESC is an electronic speed controller designed for brushless DC motors.',
        },
        {
            id: 2,
            name: 'Landing Gear Landing Skid',
            image: Product2,
            price: '446 RS',
            description: 'Four multi-axis tripod cushion D8 (black and yellow)',
            specifications: ['Material: ABS Plastic', 'Weight: 230g', 'Height: 200mm'],
            descriptionText: 'The landing gear adds extra protection to your flying platform and equipment.',
        },
        {
            id: 3,
            name: ' ORANGE HD PROPELLERS 1045(10X4.5) ABS DJI BLACK 1CW+1CCW-1PAIR PREMIUM QUALITY',
            image: Product3,
            price: '99 RS',
            description: 'Propeller Set for RC Vehicles',
            specifications: ['Length: 10inch.', 'Pitch: 4.5″.', 'Weight: 22 gm.','Shaft Diameter: 9 mm','Total Length: 10 inch / 250 mm.','Material: ABS'],
            descriptionText: 'High-quality propellers specially designed for multi-copters.',
        },
        {
            id: 4,
            name: 'ORANGE HD PROPELLERS 6045(6X4.5) GLASS FIBER NYLON PROPELLERS 2CW+2CCW-2PAIRS BLACK',
            image: Product4,
            price: '350 RS',
            description: 'Propeller Set for RC Vehicles',
            specifications: ['Length: 6inch', 'Pitch: 4.5', 'Weight: 28 gm', 'Shaft Diameter: 5mm.','total length: 6 inch / 150 mm.','material: Glass Fiber Nylon'],
    
            descriptionText: 'Introducing our Made in India Drone Proellers, meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
        {
            id: 5,
            name: 'F550 550MM HEXA-ROTOR RACING FRAME KIT',
            image: Product5,
            price: '1499 RS',
            description: 'Arm Size (L x W) mm:  155 x 34',
            specifications: ['Wheelbase: 550mm', ' Material of Frame Arm: Fiber + Nylon', ' Color of Arm: White + Black + Red', 'Weight: 424g',],
    
            descriptionText: 'Introducing our Made in India Drone Frame, meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
        {
            id: 6,
            name: 'F450 QUADCOPTER FRAME',
            image: Product6,
            price: '799 RS',
            description: 'Arm Size (L x W) mm:  155 x 34',
            specifications: ['Frame ModelF450', 'Material-UltraDurablePolyamideNylon', 'Width-450mm', 'Height-55m','Weight-270 grams','Motor Mount Bolt Holes -16/ 19mm',],
    
            descriptionText: 'Introducing our Made in India Drone Frame, meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
        {
            id: 7,
            name: 'QUADCOPTER 750MM FRAME KIT WITH LANDING GEAR CARBON FIBER',
            image: Product7,
            price: '8999 RS',
            description: 'Arm Size (L x W) mm:  155 x 34',
            specifications: ['Center plate thickness: 1.5mm', 'Arm pipe size: OD18mm', 'Wheelbase: 750mm', 'Frame with land weight: about 760g','Folding joint type: umbrella folding','Landing gear pipe size: 16mm to 10mm'],
    
            descriptionText: 'Introducing our Made in India Drone Frame Kit, meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
        {
            id: 8,
            name: ' MARTIAN-II REPTILE 250MM QUADCOPTER FRAME KIT',
            image: Product8,
            price: '2499 RS',
            description: 'Arm Size (L x W) mm:  155 x 34',
            specifications: ['Model: Martian-II', 'Material: Carbon Fiber', 'Wheelbase: 250 mm', 'Arm Thickness: 4 mm','Weight: 160 gm',],
    
            descriptionText: 'Introducing our Made in India Drone  QUADCOPTER FRAME KIT, meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
        {
            id: 9,
            name: 'A2212 10T 1400KV BRUSHLESS MOTOR FOR DRONE (SOLDERED CONNECTOR)',
            image: Product9,
            price: '450 RS',
            description: 'Arm Size (L x W) mm:  155 x 34',
            specifications: ['Motor KV: 1400', 'Weight (g):	160', 'Max. Efficiency Current: 6-12A', 'No Load Current: 0.7A @10V','Current Capacity: 16A/60S','LiPO Batteries: 2S-3S',],
    
            descriptionText: 'Introducing our Made in India BRUSHLESS MOTOR, meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
        {
            id: 10,
            name: 'A2212 13T 1400KV BRUSHLESS MOTOR FOR DRONE AND SIMONK 30A ESC',
            image: Product10,
            price: '700 RS',
            description: 'Arm Size (L x W) mm:  155 x 34',
            specifications: ['Comes in the compact sizes.', 'Suitable for airplane, aircraft, quadcopter.', 'ESC used has an On-Board BEC', 'Faster and Better speed control.',],
    
            descriptionText: 'Introducing our Made in  30A ESC, meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
        {
            id: 11,
            name: 'X TYPE CROSS HOLDER FOR 22XX SERIES BRUSHLESS MOTOR',
            image: Product11,
            price: '80 RS',
            description: 'Arm Size (L x W) mm:  155 x 34',
            specifications: ['Material: Aluminium', 'Product: X Type Cross Holder.', 'Weight: 4 gm', 'Dimensions:Refer the Dimension Diagram.',],
    
            descriptionText: 'Introducing our Made in CROSS HOLDER  , meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
        
    ];

    // Handle product card click
    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    // Close modal
    const closeModal = () => {
        setSelectedProduct(null);
    };

    // WhatsApp Purchase URL (you can replace the number with your actual business number)
    const getWhatsAppLink = (productName, productPrice) => {
        const message = `Hi, I am interested in buying the product "${productName}" for ${productPrice}.`;
        const phoneNumber = '9356983825'; // Replace with your WhatsApp business number
        return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    };

    return (
        <div className="products-section">
            {/* Product Grid */}
            <div className="product-grid">
                {productData.map((product) => (
                    <div
                        key={product.id}
                        className="product-card"
                        onClick={() => handleProductClick(product)}
                    >
                        <img src={product.image} alt={product.name} className="product-image" />
                        <h3>{product.name}</h3>
                        <p>Price: {product.price}</p>
                    </div>
                ))}
            </div>

            {/* Product Modal */}
            {selectedProduct && (
                <div className="modal">
                    <div className="modal-content modal-fullsize">
                        <span className="close-button" onClick={closeModal}>
                            &times;
                        </span>
                        <div className="modal-body">
                            <img src={selectedProduct.image} alt={selectedProduct.name} className="modal-image" />
                            <h1>{selectedProduct.name}</h1>
                            <p><strong>Price:</strong> {selectedProduct.price}</p>
                            <p><strong>Description:</strong> {selectedProduct.descriptionText}</p>
                            <h3>Specifications:</h3>
                            <ul>
                                {selectedProduct.specifications.map((spec, index) => (
                                    <li key={index}>{spec}</li>
                                ))}
                            </ul>

                            {/* WhatsApp Button */}
                            <a
                                href={getWhatsAppLink(selectedProduct.name, selectedProduct.price)}
                                className="whatsapp-button"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Buy via WhatsApp
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductPage;
